import React from 'react';
import { useTranslate, TextInput, useGetIdentity } from 'react-admin';
import StatusInput from '../StatusInput';
import { BooleanInput } from 'react-admin';

const MainTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <>
      <TextInput
        source="name"
        label={translate('name')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="description"
        label={translate('description')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="url"
        label={translate('url')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="email"
        label={translate('email')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="phone"
        label={translate('phone')}
        helperText={false}
        fullWidth
      />

      {identity?.user?.isAdmin && (
        <StatusInput
          publishDateTime={false}
          limited={false}
          mode={props.mode}
        />
      )}

      {identity?.user?.isAdmin && (
        <BooleanInput
          source="pruneEmpty"
          label={translate('pruneEmpty')}
          helperText={false}
        />
      )}
    </>
  );
};

export default MainTab;
