import { DateField, TextField } from 'ra-ui-materialui';
import React from 'react';

import {
  useTranslate,
  List,
  Datagrid,
  NumberField,
  ReferenceField,
  useGetIdentity,
} from 'react-admin';
import BooleanField from '../BooleanField';
import ChipField from '../ChipField';
import ImageField from '../ImageField';
import MyPagination from '../Pagination';
import StatusField from '../StatusField';
import PageFilter from './PageFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import UrlField from '../UrlField';
import { pagePopulate } from '../../populate';
import CategoryField from '../CategoryField';
import CheckIcon from '@material-ui/icons/Check';
import DomainField from '../DomainField';
import TitleField from '../TitleField';
import { CloneButton } from '../CloneButton';
import AccountDesactivated from '../AccountDesactivated';

const FolderField = ({ record }: any) => {
  if (!record) {
    return <></>;
  }

  const label = record.folder ? 'Dossier' : 'Page';
  const opacity = record.folder ? 1 : 0.5;
  return (
    <ChipField
      record={record}
      label={label}
      color={'#fff'}
      backgroundColor={`rgba(0, 148, 160,${opacity})`}
    />
  );
};

const PinnedField = ({ record }: any) => {
  if (!record) {
    return <></>;
  }
  return record.is_pinned ? <CheckIcon /> : <></>;
};

const LocationField = ({ record }: any) => {
  if (!record) {
    return <></>;
  }
  return record.geometry?.latitude && record.geometry?.longitude ? (
    <CheckIcon />
  ) : (
    <></>
  );
};

const PageList = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <List
      title={translate('pages')}
      filter={{ _populate: pagePopulate.join(',') }}
      filters={<PageFilter />}
      filterDefaultValues={{ status: 'published' }}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      bulkActionButtons={identity?.user?.isAdmin}
      sort={{ field: 'created_at', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="edit">
        {identity?.user?.isAdmin && (
          <NumberField source="id" id={translate('domain')} />
        )}
        {identity?.user?.isAdmin && (
          <DomainField source="domain" label={translate('domain')} />
        )}
        {identity?.user?.isAdmin && (
          <TextField
            source="configuration.target"
            label={translate('configuration.target')}
          />
        )}
        <ImageField label={''} />
        <TitleField source="title" label={translate('title')} />

        {identity?.user?.isAdmin && <PinnedField label={translate('home')} />}
        {identity?.user?.isAdmin && (
          <LocationField label={translate('geometry')} />
        )}
        {!isXSmall && <CategoryField source="category" label={''} />}
        {!isXSmall && <UrlField source="reference" label="" />}
        {identity?.user?.isAdmin && (
          <BooleanField source="hideMenu" label={translate('hideMenu_list')} />
        )}
        {!isXSmall && <FolderField label={translate('type')} />}
        {!isXSmall && (
          <ReferenceField
            source="parent"
            reference="page"
            label={translate('folder')}
            filter={{
              folder: true,
              status: 'published',
              ...(identity?.user.domain
                ? { domain: identity?.user.domain }
                : {}),
              _populate: pagePopulate.join(','),
            }}
          >
            <TextField source="title" />
          </ReferenceField>
        )}
        {!isXSmall && <StatusField label={translate('status')} />}
        {!isXSmall && (
          <NumberField
            source="sort"
            label={translate('sort_list')}
            style={{ width: 250 }}
          />
        )}
        {!isXSmall && (
          <DateField
            source="publish_at"
            showTime={false}
            label={translate('publish_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="created_at"
            showTime={true}
            label={translate('created_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="updated_at"
            showTime={true}
            label={translate('updated_at')}
          />
        )}
        <CloneButton />
      </Datagrid>
    </List>
  ) : (
    <AccountDesactivated />
  );
};

export default PageList;
