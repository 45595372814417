import Compressor from 'compressorjs';
import _ from 'lodash';

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    new Compressor(file.rawFile, {
      quality: process.env.REACT_APP_IMAGE_QUALITY
        ? (process.env.REACT_APP_IMAGE_QUALITY || 80) / 100
        : 90,
      maxWidth: process.env.REACT_APP_IMAGE_MAX_WIDTH
        ? parseInt(process.env.REACT_APP_IMAGE_MAX_WIDTH)
        : 1200,
      maxHeight: process.env.REACT_APP_IMAGE_MAX_HEIGHT
        ? process.env.REACT_APP_IMAGE_MAX_HEIGHT
        : 1200,
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedResult);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      },
      error(err) {
        resolve(null);
      },
    });
  });

const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if (type === 'CREATE' || type === 'UPDATE') {
    const promises = [];
    if (params.data.image && params.data.image.rawFile) {
      promises.push(
        convertFileToBase64(params.data.image).then((base64) => {
          if (base64) {
            params.data = {
              ...params.data,
              image: base64,
            };
          }
        })
      );
    } else if (params.data.clone && params.data.imageClone) {
      params.data.image = params.data.imageClone;
    } else if (params.data.removeImage) {
      params.data.image = null;
      params.data.imageCDNParams = null;
      params.data.imageCDNPath = null;
    } else {
      delete params.data.image;
    }
    if (params.data.icon && params.data.icon.rawFile) {
      promises.push(
        convertFileToBase64(params.data.icon).then((base64) => {
          params.data = {
            ...params.data,
            icon: base64,
          };
        })
      );
    } else if (params.data.removeIcon) {
      params.data.icon = null;
      params.data.iconCDNParams = null;
      params.data.iconCDNPath = null;
    } else {
      delete params.data.icon;
    }

    if (params.data.imageClone) {
      delete params.data.imageClone;
    }
    if (params.data.sent_date) {
      delete params.data.sent_date;
    }
    if (params.data.pushTickets) {
      delete params.data.sent_date;
    }
    if (params.data.pushReceipts) {
      delete params.data.sent_date;
    }

    return Promise.all(promises).then(() => {
      if (params.data.pruneEmpty) {
        params = pruneEmpty(params);
        delete params.data.pruneEmpty;
      }
      return requestHandler(type, resource, params);
    });
  }
  return requestHandler(type, resource, params);
};

export default addUploadFeature;

function pruneEmpty(obj) {
  return (function prune(current) {
    _.forOwn(current, function (value, key) {
      if (
        _.isUndefined(value) ||
        _.isNull(value) ||
        _.isNaN(value) ||
        (_.isString(value) && _.isEmpty(value)) ||
        (_.isObject(value) && _.isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (_.isArray(current)) _.pull(current, undefined);

    return current;
  })(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead
}
