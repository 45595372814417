import React from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import { BooleanInput } from 'react-admin';

const ConfigTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <>
      {identity?.user?.isAdmin && (
        <BooleanInput
          source="configuration.disableFavorite"
          label={translate('configuration.disableFavorite')}
          helperText={false}
          fullWidth
        />
      )}

      {identity?.user?.isAdmin && !props.disableSharing && (
        <BooleanInput
          source="configuration.disableSharing"
          label={translate('configuration.disableSharing')}
          helperText={false}
          fullWidth
        />
      )}

      {identity?.user?.isAdmin && (
        <BooleanInput
          source="configuration.disablePropulsed"
          label={translate('configuration.disablePropulsed')}
          helperText={false}
          fullWidth
        />
      )}

      {identity?.user?.isAdmin && (
        <BooleanInput
          source="configuration.disableReload"
          label={translate('configuration.disableReload')}
          helperText={false}
          fullWidth
        />
      )}
    </>
  );
};

export default ConfigTab;
