import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,
  pos: {
    search: 'Rechercher',
    language: 'Langue',
    theme: {
      name: 'Theme',
      light: 'Clair',
      dark: 'Obscur',
    },
  },
  id: 'Id',
  admin: 'Root',
  authenticated: 'Administration',
  name: 'Nom',
  title: 'Titre',
  subtitle: 'Description',
  body: 'Contenu',
  template: 'Modèle',
  search: 'Rechercher',
  searchName: 'Name',
  searchUuid: 'Uuid',
  searchToken: 'Token',
  region: 'Région',
  regions: 'Régions',
  department: 'Département',
  departments: 'Départements',
  city: 'Ville',
  cities: 'Villes',
  page: 'Page',
  pages: 'Pages',
  article: 'Actualité',
  articles: 'Actualités',
  event: 'Agenda',
  events: 'Agenda',
  image: 'Image',
  images: 'Images',
  imageMaxSize: 'Photo (Max 1M)',
  iconMaxSize: 'Icône (Max 1M)',
  icon: 'Icône',
  parent: 'Parent',
  reference: 'Lien internet',
  referenceHelp: `https:// ou http:// ou tel:{telephone} ou mailto:{email}. Pour un lien de type email ou téléphone, l'option "ouvrir en dehors de l'application" doit être cochée`,
  content: 'Contenu',
  contents: 'Contenus',
  domain: 'Domaine',
  domains: 'Domaines',
  favorites: 'Favoris',
  golfScoreCard: 'Carte de score',
  menu: 'Menu',
  domain_admin: 'Domaine ✪',
  rootDomain: 'Domaine parent',
  list: 'Liste',
  agenda: 'Calendrier',
  link: 'Lien',
  uuid: 'Identifiant technique',
  Forbidden: 'Interdit',
  description: 'Description',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  url: 'Url',
  email: 'Email',
  map: 'Map',
  phone: 'Téléphone',
  device: 'Appareil',
  devices: 'Appareils',
  notification: 'Notification',
  notifications: 'Notifications',
  send: 'Envoyer',
  sent: 'Envoyer',
  'An internal server error occurred': 'Une erreur interne est survenue',
  'Network error': 'Problème de connexion',
  postal: 'Code postal',
  slug: 'Slug',
  urlSlug: 'Url Slug',
  youtube: 'Youtube',
  is_pinned: 'Raccourci accueil',
  appVersion: 'Version',
  category: 'Catégorie',
  categories: 'Catégories',
  categories_admin: 'Catégories ✪',
  key: 'Key',
  value: 'Value',
  folder: 'Dossier',
  platform: 'Plateforme',
  created_at: 'Date de création',
  updated_at: 'Date de mise à jour',
  publish_at: 'Date de publication',
  archive_at: `Date d'archivage`,
  date: 'Date',
  token: 'Token',
  theme: 'Thème',
  home: 'Accueil',
  domainType: 'Type',
  town: 'Mairie',
  towns: 'Mairies',
  adress: 'Adresse',
  'Failed to fetch': 'Failed to fetch',
  sort: 'Ordre de tri',
  sort_list: 'Ordre',
  isSent: 'Envoyé',
  school: 'Ecole',
  schools: 'Ecoles',
  isAuthenticated: 'Admin',
  type: 'Type',
  secteur: 'Secteur',
  academy: 'Academie',
  resource: 'Ressource',
  resources: 'Ressources',
  imageInput: 'Sélectionner une nouvelle image (.png.jpg.webp)',
  iconInput: 'Sélectionner une nouvelle icône (.png.jpg.webp)',
  currentImage: 'Image actuelle',
  newImage: 'Nouvelle image',
  file: 'Fichier',
  draft: 'Brouillon',
  published: 'Publié',
  scheduled: 'Programmé',
  archive: 'Archivé',
  sent_date: "Date d'envoi",
  sent_programmed_date: 'Date de publication',
  sent_real_date: "Date d'envoi réel",
  user: 'Utilisateur',
  users: 'Utilisateurs',
  users_admin: 'Utilisateurs ✪',
  role: 'Rôle',
  roles: 'Rôles',
  password: 'Mot de passe',
  provider: 'Provider',
  username: 'Identifiant',
  status: 'Etat',
  start_date: 'Début',
  end_date: 'Fin',
  removeImage: `Supprimer l'image`,
  removeIcon: `Supprimer l'icône`,
  qrcode: 'QR Code',
  qrcodes: 'QR Codes',
  openInBrowser: `Ouvrir le lien en dehors de l'application`,
  openInBrowserHelp: ``,
  is_sent: 'Envoyé',
  is_scheduled: 'Programmé',
  is_not_sent: 'Non envoyé',
  linkedin: 'Linkedin',
  version: 'Version',
  versions: 'Versions',
  hiddable: "Autoriser le filtrage de cette catégorie dans l'application",
  hiddable_list: 'Filtrage autorisé',
  settings: 'Paramètres',
  settings_authenticated: 'Paramètres (admin)',
  hideMenu: 'Masquer dans le menu',
  hideTitle: 'Masquer le titre',
  hideDescription: 'Masquer la description',
  hideCategory: 'Masquer la catégorie',
  hideLocation: 'Masquer la position',
  hideMenu_list: 'Masqué',
  help: {
    content:
      'Indiquez un lien internet ou saisissez directement le contenu souhaité.',
  },
  infos: 'Infos',
  fullDay: 'Toute la journée',
  section: 'Rubrique',
  sections: 'Rubriques',
  options: 'Options',
  dates: 'Dates',
  ra: {
    ...frenchMessages.ra,
    action: {
      ...frenchMessages.ra.action,
      add_filter: 'Filtrer',
    },
  },
  rss: 'Flux RSS',
  design: 'Apparence',
  configuration: {
    pagination: {
      position: {
        title: 'Pagination (position)',
        bottom: 'Bottom',
        top: 'Top',
      },
    },
    archive: {
      label: 'Archive',
      notFound: 'Archive  404',
    },
    label: 'Configuration',
    mainColor: 'Couleur principale',
    mainColor_list: 'Couleur',
    accentColor: 'Couleur secondaire',
    fetch: {
      image: 'Image CSS selector',
    },
    customCSS: 'Custom CSS',
    customJS: 'customJS',
    disablePage: 'Désactiver les pages',
    disableCategory: 'Désactiver les catégories',
    disableDevice: 'Désactiver les devices',
    disableAdmin: `Désactiver l'administration`,
    disableArticle: 'Désactiver les actualités',
    disableNotification: 'Désactiver les notifications',
    disableEvent: 'Désactiver les événements',
    disableUser: 'Désactiver les utilisateurs',
    disableWebView: 'Désactiver les webViews',
    disableSharing: 'Désactiver le partage',
    disablePropulsed: 'Désactiver le widget Propulsed By',
    disableWeather: 'Désactiver la météo',
    disableHours: 'Désactiver les horaires',
    disableFavorite: 'Désactiver la mise en favori',
    disableReload: 'Désactiver le reload',
    enableOpenInBrowser: `Autoriser l'ouverture dans le navigateur`,
    enableLoyalty: 'Activer la carte de fidélité',
    enableHours: 'Activer les horaires',
    enableMap: 'Activer les cartes',
    rss: {
      disabled: 'Désactivation du flux RSS',
    },
    wpJson: {
      disabled: 'Désactivation du flux WP JSON',
    },
    defaultTheme: 'Thème par défaut',
    homepageDefaultWidgets:
      'Widgets accueil par défaut (actualités / à la une / favoris)',
    disableLocation: `Désactiver l'adresse`,
    homepage: {
      hideImage: `Masquer l'image d'entête sur la home`,
    },
    hideName: 'Masquer le nom du domaine dans le menu',
    iconBackgroundColor: 'Couleur de fond du logo',
    privacyUrl: 'Url Vie privée',
    legalUrl: 'Url Mentions légales',
    contactEmail: 'Email de contact',
    shareUrl: 'Url Partage',
    registerUrl: 'Url Register',
    supportUrl: 'Url Support',
    releasesUrl: 'Release Url',
    caniuseUrl: 'Can I Use Url',
    kitCommunicationUrl: 'Kit de Communication',
    highlighted: 'Highlighted domains',
    target: 'Target',
    fullscreen: 'Mode Fullscreen',
    id: 'Identifiant',
    params: 'Params',
    view: "Type d'affichage",
    hideImage: "Masquer l'image",
    hideTitle: `Masquer le titre`,
    hideCategory: `Masquer la catégorie`,
    hideBadge: `Masquer le badge`,
    disableEventFilters: `Désativer les filtres sur l'agenda`,
    enableAPI: `Activer l'accès à l'API`,
    lowDataMode: 'Mode faibles données',
    pack: {
      renewalDate: 'Date de renouvellement',
      arr: 'Montant annuel',
    },
    imageOrientation: `Orientation de l'image`,
    columns: 'Nombre de colonnes',
    sort: {
      property: 'Propriété sur laquelle trier',
      direction: 'Sens de tri',
      asc: 'Ascendant',
      desc: 'Descendant',
    },
    events: {
      notify: 'Notification auto à J-%{days}',
      notify_short: 'Notif J-%{days}',
      notif: 'Notif',
    },
    folder: 'Configuration du dossier',
    hidePageTitle: 'Masquer le titre des pages',
    hubspotId: 'ID Hubspot',

    appStoreVersion: 'Store version',
    requestReviewFrequency: 'Review',
    appStore: {
      rating: 'Note *',
      reviews: `Avis *`,
    },
    enableDebug: 'Debug',
    settings: {
      disableTheme: 'Désactiver le thème',
    },
    placeholderUrl: 'Image par défaut',
  },
  socials: 'Réseaux sociaux',
  enableImageDownload: "Autoriser l'enregistrement de l'image",
  pack: 'Pack',
  root: 'Root',
  square: 'Carré',
  landscape: 'Paysage',
  portrait: 'Portrait',
  none: 'Aucun',
  round: 'Rond',
  light: 'Clair',
  dark: 'Sombre',
  system: 'Système',
  fff: 'Clubs de Foot',
  namespaces: 'NameSpaces',
  brick: 'Brique',
  card: 'Card',
  table: 'Tableau',
  folderOrPage: 'Page ou Dossier',
  parentFolder: 'Répertoire / Dossier parent',
  development: 'Development',
  development_short: 'Dev.',
  country: 'Pays',
  countries: 'Pays',
  localizations: 'Géographie',
  latitude: 'Latitude',
  longitude: 'Longitude',
  latitudeDelta: 'latitudeDelta',
  longitudeDelta: 'longitudeDelta',
  noCity: 'Aucune ville',
  widget: 'Widgets',
  ios: 'IOS',
  android: 'Android',
  isUrlHelper: 'Type de contenu',
  actions: {
    title: "Boutons d'action",
    icon: 'Icône du bouton',
    label: 'Libellé du bouton',
    type: `Type d'action`,
    value: 'Action du bouton',
    web: 'Site Web',
    email: 'Envoyer un email',
    phone: 'Appeler',
  },
  action: {
    web: 'Ouvrir le site internet',
    email: 'Envoyer un email',
    phone: 'Appeler un numéro',
    map: `Afficher l'itinéraire`,
  },
  internal: 'Action interne',
  wpjson: 'Flux WP JSON',
  hours: {
    label: `Horaires d'ouverture`,
    from: 'Ouverture',
    to: 'Fermeture',
  },
  days: {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
  },
  widgets: {
    title: 'Titre',
    description: 'Description',
    type: 'Type',
    disabled: 'Désactiver',
    pages: 'Pages',
    events: 'Evénements',
    articles: 'Actualités (not authorized yet)',
    notifications: 'Notifications',
    news: 'Actualités',
    html: 'Bloc HTML',
    buttons: 'Boutons',
    button: 'Bouton',
    timer: 'Timer',
    domains: 'Domaines',
    columns: 'Nombre de colonnes',
    date: 'Date',
    limit: 'Limite',
    hideDate: 'Masquer la date',
    hideTitle: 'Masquer le titre',
    hideDescription: 'Masquer la description',
    hideCategory: 'Masquer la catégorie',
    hideLocation: 'Masquer la position',
    fullscreen: 'Mode fullscreen',
    showPinned: `Afficher les éléments accrochés`,
    showFavorite: 'Afficher les favoris',
    target: 'Target',
    params: 'Params',
    htmlCode: 'HTML',
    icon: 'Icône',
    hours: `Horaires d'ouverture`,
    propulsed: 'Propulsé par',
    loyalty: 'Carte de fidélité',
    subdomains: 'Subdomains',
    weather: 'Météo',
    golfScoreCard: 'Carte de scores (golf)',
  },
  slide: 'Carousel',
  loyalty: {
    label: 'Carte fidélité',
    title: `Titre dans l'écran dans l'application`,
    description: `Sous-titre dans l'écran dans l'application`,
    points: 'Nombre de points disponibles sur la carte',
    password: 'Mot de passe commerçant',
  },
  overlay: {
    icon: 'Icône',
    label: 'Label',
    models: {
      video: 'Vidéo',
      photo: 'Photo',
      newspaper: 'Magazine',
      plus: 'Plus +',
    },
  },
  marker: {
    title: 'Marker (map)',
    image: 'Image',
    backgroundColor: ' BgColor',
    size: 'Size',
    label: 'Libellé',
  },
  geometry: {
    title: 'Géometrie',
  },
  tabs: {
    title: 'Tabs',
    label: 'Label',
    icon: 'Icône',
    type: 'Type',
    params: 'Paramètres',
  },
  tags: {
    label: 'Tags',
  },
  pruneEmpty: 'Supprimer les valeurs nulles',
};

export default customFrenchMessages;
